import React, { useState, useEffect, createContext, useContext } from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { BarChart, LineChart, Bar, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import logoArcotel from './logo-arcotel.png';
import arcotel from './arcotel.png';
import arcotelDunkel from './arcotel-dunkel.png';

const USERNAME = process.env.REACT_APP_USERNAME;
const PASSWORD = process.env.REACT_APP_PASSWORD;

const ThemeContext = createContext();
const AuthContext = createContext();

let API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
let AKW_API_BASE_URL = process.env.REACT_APP_AKW_API_BASE_URL;

const ThemeProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useState(false);
  return (
    <ThemeContext.Provider value={{ darkMode, setDarkMode }}>
      {children}
    </ThemeContext.Provider>
  );
};

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const login = (username, password) => {
    if (username === USERNAME && password === PASSWORD) {
      setIsAuthenticated(true);
      return true;
    }
    return false;
  };

  const logout = () => {
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const { login } = useContext(AuthContext);
  const [error, setError] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    const success = login(username, password);
    if (!success) {
      setError('Falscher Benutzername oder Passwort');
    }
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <form onSubmit={handleSubmit} className="bg-white p-6 rounded shadow-md">
        <h1 className="text-2xl font-bold mb-4">Login</h1>
        {error && <p className="text-red-500">{error}</p>}
        <input
          type="text"
          placeholder="Benutzername"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          className="p-2 border rounded mb-4 w-full"
        />
        <div className="relative mb-4">
          <input
            type={showPassword ? 'text' : 'password'}
            placeholder="Passwort"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="p-2 border rounded w-full"
          />
          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            className="absolute right-2 top-2 text-gray-600"
          >
            {showPassword ? '👁️‍🗨️' : '👁️'}
          </button>
        </div>
        <button type="submit" className="bg-blue-500 text-white p-2 rounded w-full">
          Login
        </button>
      </form>
    </div>
  );
};

const FAQs = ({ darkMode }) => {
  const [questions, setQuestions] = useState([]);
  const [newQuestion, setNewQuestion] = useState('');
  const [newAnswer, setNewAnswer] = useState('');

  useEffect(() => {
    fetchQuestions();
  }, []);

  const fetchQuestions = async () => {
    try {
      const response = await fetch(`${AKW_API_BASE_URL}/api/bot_knowledge`);
      if (!response.ok) {
        throw new Error('Error fetching bot knowledge');
      }
      const data = await response.json();
      setQuestions(data);
    } catch (error) {
      console.error('Error fetching questions:', error);
    }
  };

  const handleAddQuestion = async () => {
    try {
      const response = await fetch(`${AKW_API_BASE_URL}/api/bot_knowledge`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ question: newQuestion, answer: newAnswer }),
      });

      if (!response.ok) {
        throw new Error('Error adding question');
      }

      setNewQuestion('');
      setNewAnswer('');
      fetchQuestions();
    } catch (error) {
      console.error('Error adding question:', error);
    }
  };

  const handleDeleteQuestion = async (id) => {
    try {
      const response = await fetch(`${AKW_API_BASE_URL}/api/bot_knowledge/${id}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error('Error deleting question');
      }

      fetchQuestions();
    } catch (error) {
      console.error('Error deleting question:', error);
    }
  };

  return (
    <div className={`p-4 ${darkMode ? 'bg-gray-800 text-white' : 'bg-white'}`}>
      <h2 className="text-xl font-bold mb-4">FAQs</h2>
      <div className="mb-4">
        <h3 className="text-lg font-bold">Frage hinzufügen</h3>
        <input
          type="text"
          placeholder="Frage"
          value={newQuestion}
          onChange={(e) => setNewQuestion(e.target.value)}
          className={`p-2 border rounded mb-2 w-full ${darkMode ? 'bg-gray-700 text-white' : 'bg-white'}`}
        />
        <textarea
          placeholder="Antwort"
          value={newAnswer}
          onChange={(e) => setNewAnswer(e.target.value)}
          className={`p-2 border rounded mb-2 w-full ${darkMode ? 'bg-gray-700 text-white' : 'bg-white'}`}
        />
        <button
          onClick={handleAddQuestion}
          className="p-2 rounded bg-green-500 text-white w-full"
        >
          Frage hinzufügen
        </button>
      </div>
      <div>
        <h3 className="text-lg font-bold">Vorhandene Fragen</h3>
        {questions.length > 0 ? (
          questions.map((item) => (
            <div key={item.id} className={`p-2 border rounded mb-2 ${darkMode ? 'bg-gray-700' : 'bg-white'}`}>
              <p className="font-bold">{item.question}</p>
              <p>{item.answer}</p>
              <button
                onClick={() => handleDeleteQuestion(item.id)}
                className="text-red-500 mt-2"
              >
                🗑️ Frage löschen
              </button>
            </div>
          ))
        ) : (
          <p>Keine Fragen vorhanden</p>
        )}
      </div>
    </div>
  );
};

const DatabaseSettings = ({ darkMode }) => {
  const [urls, setUrls] = useState([]);
  const [newUrl, setNewUrl] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const urlsPerPage = 10;

  useEffect(() => {
    fetchUrls();
  }, []);

  const fetchUrls = async () => {
    try {
      const response = await fetch(`${AKW_API_BASE_URL}/api/knowledge_base`);
      if (!response.ok) {
        throw new Error('Error fetching knowledge base URLs');
      }
      const data = await response.json();
      const extractedUrls = data.map(item => item.url);
      setUrls(extractedUrls);
    } catch (error) {
      console.error('Error fetching knowledge base URLs:', error);
    }
  };

  const handleAddUrl = async () => {
    if (!newUrl.trim()) return;

    try {
      const response = await fetch(`${AKW_API_BASE_URL}/api/knowledge_base`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ urls: [newUrl] }),
      });

      if (!response.ok) {
        throw new Error('Error adding URL');
      }

      setNewUrl('');
      fetchUrls();
    } catch (error) {
      console.error('Error adding URL:', error);
    }
  };

  const handleDeleteUrl = async (urlToDelete) => {
    try {
      const response = await fetch(`${AKW_API_BASE_URL}/api/knowledge_base`);
      if (!response.ok) {
        throw new Error('Error fetching knowledge base URLs');
      }
      const data = await response.json();
      const itemToDelete = data.find(item => item.url === urlToDelete);

      if (itemToDelete) {
        const deleteResponse = await fetch(`${AKW_API_BASE_URL}/api/knowledge_base/${itemToDelete.id}`, {
          method: 'DELETE',
        });

        if (!deleteResponse.ok) {
          throw new Error('Error deleting URL');
        }

        fetchUrls();
      }
    } catch (error) {
      console.error('Error deleting URL:', error);
    }
  };

  const indexOfLastUrl = currentPage * urlsPerPage;
  const indexOfFirstUrl = indexOfLastUrl - urlsPerPage;
  const currentUrls = urls.slice(indexOfFirstUrl, indexOfLastUrl);
  const totalPages = Math.ceil(urls.length / urlsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className={`p-4 ${darkMode ? 'bg-gray-800 text-white' : 'bg-white'}`}>
      <h2 className="text-xl font-bold mb-4">Datenbank Verwaltung</h2>
      <div className="mb-4">
        <h3 className="text-lg font-bold">Neue URL hinzufügen</h3>
        <input
          type="text"
          placeholder="Neue URL"
          value={newUrl}
          onChange={(e) => setNewUrl(e.target.value)}
          className={`p-2 border rounded mb-2 w-full ${darkMode ? 'bg-gray-700 text-white' : 'bg-white'}`}
        />
        <button
          onClick={handleAddUrl}
          className="p-2 rounded bg-green-500 text-white w-full"
        >
          URL hinzufügen
        </button>
      </div>
      <div>
        <h3 className="text-lg font-bold">Aktuelle URLs</h3>
        {currentUrls.length > 0 ? (
          currentUrls.map((url, index) => (
            <div key={index} className={`p-2 border rounded mb-2 ${darkMode ? 'bg-gray-700' : 'bg-white'}`}>
              <p className="font-bold">{url}</p>
              <button
                onClick={() => handleDeleteUrl(url)}
                className="text-red-500 mt-2"
              >
                🗑️ URL löschen
              </button>
            </div>
          ))
        ) : (
          <p>Keine URLs vorhanden</p>
        )}
        <div className="mt-4">
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index + 1}
              onClick={() => handlePageChange(index + 1)}
              className={`p-2 mx-1 ${currentPage === index + 1 ? 'bg-blue-500 text-white' : 'bg-gray-300'} rounded`}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

const BotSettings = ({ darkMode, botConfig, updateBotConfig, saveBotConfig }) => {
  return (
    <div className={`p-4 ${darkMode ? 'bg-gray-800 text-white' : 'bg-white'}`}>
      <h2 className="text-xl font-bold mb-4">Bot Einstellungen</h2>
      <div className="mb-4">
        <label className="block text-sm font-bold mb-2">Hotelname</label>
        <input
          type="text"
          value={botConfig.hotelName}
          onChange={(e) => updateBotConfig('hotelName', e.target.value)}
          className={`p-2 border rounded w-full ${darkMode ? 'bg-gray-700 text-white' : 'bg-white'}`}
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-bold mb-2">Hotelkürzel</label>
        <input
          type="text"
          value={botConfig.hotelShortcode}
          onChange={(e) => updateBotConfig('hotelShortcode', e.target.value)}
          className={`p-2 border rounded w-full ${darkMode ? 'bg-gray-700 text-white' : 'bg-white'}`}
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-bold mb-2">Willkommensnachricht</label>
        <input
          type="text"
          value={botConfig.welcomeMessage}
          onChange={(e) => updateBotConfig('welcomeMessage', e.target.value)}
          className={`p-2 border rounded w-full ${darkMode ? 'bg-gray-700 text-white' : 'bg-white'}`}
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-bold mb-2">Twilio Telefonnummer</label>
        <input
          type="text"
          value={botConfig.twilioNumber}
          onChange={(e) => updateBotConfig('twilioNumber', e.target.value)}
          className={`p-2 border rounded w-full ${darkMode ? 'bg-gray-700 text-white' : 'bg-white'}`}
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-bold mb-2">Port</label>
        <input
          type="text"
          value={botConfig.port}
          onChange={(e) => updateBotConfig('port', e.target.value)}
          className={`p-2 border rounded w-full ${darkMode ? 'bg-gray-700 text-white' : 'bg-white'}`}
        />
      </div>
      <button onClick={saveBotConfig} className="bg-blue-500 text-white p-2 rounded w-full">
        Speichern
      </button>
    </div>
  );
};

const AdvancedCallTrackingDashboard = () => {
  const { darkMode, setDarkMode } = useContext(ThemeContext);
  const { isAuthenticated, logout } = useContext(AuthContext);
  const [activeTab, setActiveTab] = useState('dashboard');
  const [selectedBot, setSelectedBot] = useState('ARCOTEL Voicebot');
  const [bots, setBots] = useState(['ARCOTEL Voicebot', 'ARCOTEL Kaiserwasser']);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [conversations, setConversations] = useState([]);
  const [selectedConversationId, setSelectedConversationId] = useState(null);
  const [stats, setStats] = useState([]);
  const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
  const [chartType, setChartType] = useState('bar');
  const [showCreateBotModal, setShowCreateBotModal] = useState(false);
  const [botConfig, setBotConfig] = useState({
    hotelName: '',
    hotelShortcode: '',
    welcomeMessage: '',
    language: 'de',
    twilioNumber: '',
    port: '',
  });

  useEffect(() => {
    fetchConfig(); // Load the configuration on startup
  }, []);

  useEffect(() => {
    fetchStats();
    fetchConversations();
    const interval = setInterval(() => {
      fetchStats();
      fetchConversations();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [startDate, endDate, selectedBot]);

  const fetchConfig = async () => {
    try {
      const response = await fetch('/config.json'); // Fetching the config file
      if (!response.ok) {
        throw new Error('Error fetching config');
      }
      const config = await response.json();

      // Set API base URLs based on the selected bot
      if (selectedBot === 'ARCOTEL Kaiserwasser' || selectedBot === botConfig.hotelName) {
        AKW_API_BASE_URL = config[selectedBot]?.api_url || 'http://localhost:8081';
      } else {
        API_BASE_URL = config[selectedBot]?.api_url || 'http://localhost:8080';
      }
    } catch (error) {
      console.error('Error loading config:', error);
    }
  };

  const fetchStats = async () => {
    try {
      const url = selectedBot === 'ARCOTEL Kaiserwasser' || selectedBot === botConfig.hotelName
        ? `${AKW_API_BASE_URL}/api/get_kaiserwasser_stats?start_date=${startDate}&end_date=${endDate}`
        : `${API_BASE_URL}/api/get_stats?start_date=${startDate}&end_date=${endDate}`;

      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Error fetching stats: ${response.statusText}`);
      }
      const data = await response.json();
      setStats(data);
    } catch (error) {
      console.error("Error fetching stats:", error);
    }
  };

  const fetchConversations = async () => {
    try {
      const url = selectedBot === 'ARCOTEL Kaiserwasser' || selectedBot === botConfig.hotelName
        ? `${AKW_API_BASE_URL}/api/get_kaiserwasser_conversations`
        : `${API_BASE_URL}/api/get_conversations`;

      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Error fetching conversations: ${response.statusText}`);
      }
      const data = await response.json();
      setConversations(data);
    } catch (error) {
      console.error("Error fetching conversations:", error);
    }
  };

  const deleteConversation = async (id) => {
    try {
      const url = selectedBot === 'ARCOTEL Kaiserwasser' || selectedBot === botConfig.hotelName
        ? `${AKW_API_BASE_URL}/api/delete_conversation/${id}`
        : `${API_BASE_URL}/api/delete_conversation/${id}`;

      await fetch(url, { method: 'DELETE' });
      setConversations(conversations.filter(conv => conv.id !== id));
      if (selectedConversationId === id) {
        setSelectedConversationId(null);
      }
    } catch (error) {
      console.error("Error deleting conversation:", error);
    }
  };

  const chartData = stats.map(day => {
    if (selectedBot === 'ARCOTEL Kaiserwasser' || selectedBot === botConfig.hotelName) {
      return {
        name: day.date,
        Rezeption: day.reception_calls_akw,
        Deutsch: day.german_calls_akw,
        Englisch: day.english_calls_akw,
      };
    } else {
      return {
        name: day.date,
        Geschäftlich: day.business_calls,
        Privat: day.private_calls,
        Buchungslinks: day.booking_links_sent,
        Buchungsfragen: day.existing_booking_calls
      };
    }
  });

  const renderChart = () => {
    const ChartComponent = chartType === 'bar' ? BarChart : LineChart;
    const colors = selectedBot === 'ARCOTEL Kaiserwasser' || selectedBot === botConfig.hotelName
      ? ['#8884d8', '#82ca9d', '#ffc658']
      : ['#8884d8', '#82ca9d', '#ffc658', '#ff7f50'];

    return (
      <div className="h-64">
        <ResponsiveContainer width="100%" height="100%">
          <ChartComponent data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" stroke={darkMode ? "#fff" : "#666"} />
            <YAxis stroke={darkMode ? "#fff" : "#666"} />
            <Tooltip contentStyle={{ backgroundColor: darkMode ? '#333' : '#fff', color: darkMode ? '#fff' : '#333' }} />
            <Legend />
            {chartType === 'bar' ? (
              selectedBot === 'ARCOTEL Kaiserwasser' || selectedBot === botConfig.hotelName ? (
                <>
                  <Bar dataKey="Rezeption" fill={colors[0]} />
                  <Bar dataKey="Deutsch" fill={colors[1]} />
                  <Bar dataKey="Englisch" fill={colors[2]} />
                </>
              ) : (
                <>
                  <Bar dataKey="Geschäftlich" fill={colors[0]} />
                  <Bar dataKey="Privat" fill={colors[1]} />
                  <Bar dataKey="Buchungslinks" fill={colors[2]} />
                  <Bar dataKey="Buchungsfragen" fill={colors[3]} />
                </>
              )
            ) : (
              selectedBot === 'ARCOTEL Kaiserwasser' || selectedBot === botConfig.hotelName ? (
                <>
                  <Line type="monotone" dataKey="Rezeption" stroke={colors[0]} />
                  <Line type="monotone" dataKey="Deutsch" stroke={colors[1]} />
                  <Line type="monotone" dataKey="Englisch" stroke={colors[2]} />
                </>
              ) : (
                <>
                  <Line type="monotone" dataKey="Geschäftlich" stroke={colors[0]} />
                  <Line type="monotone" dataKey="Privat" stroke={colors[1]} />
                  <Line type="monotone" dataKey="Buchungslinks" stroke={colors[2]} />
                  <Line type="monotone" dataKey="Buchungsfragen" stroke={colors[3]} />
                </>
              )
            )}
          </ChartComponent>
        </ResponsiveContainer>
      </div>
    );
  };

  const exportPDF = () => {
    const dashboardElement = document.getElementById('dashboard-content');
    html2canvas(dashboardElement).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      const imgWidth = pdf.internal.pageSize.getWidth();
      const imgHeight = canvas.height * imgWidth / canvas.width;
      pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
      pdf.save('dashboard.pdf');
    });
  };

  const renderDashboard = () => (
    <div id="dashboard-content" className={`p-4 ${darkMode ? 'bg-gray-800 text-white' : 'bg-white'}`}>
      <h2 className="text-xl font-bold mb-4">Dashboard - {selectedBot}</h2>
      <div className="flex justify-between items-center mb-4 flex-wrap space-y-2 sm:space-y-0">
        <div className="flex space-x-2">
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className={`p-2 border rounded ${darkMode ? 'bg-gray-700 text-white' : 'bg-white'}`}
          />
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className={`p-2 border rounded ${darkMode ? 'bg-gray-700 text-white' : 'bg-white'}`}
          />
        </div>
        <div className="flex space-x-2">
          <select
            value={selectedBot}
            onChange={(e) => setSelectedBot(e.target.value)}
            className={`p-2 border rounded ${darkMode ? 'bg-gray-700 text-white' : 'bg-white'}`}
          >
            {bots.map((bot, index) => (
              <option key={index} value={bot}>{bot}</option>
            ))}
          </select>
          <button
            onClick={() => setChartType('bar')}
            className={`p-2 rounded ${chartType === 'bar' ? 'bg-blue-500 text-white' : darkMode ? 'bg-gray-600' : 'bg-gray-200'}`}
          >
            📊
          </button>
          <button
            onClick={() => setChartType('line')}
            className={`p-2 rounded ${chartType === 'line' ? 'bg-blue-500 text-white' : darkMode ? 'bg-gray-600' : 'bg-gray-200'}`}
          >
            📈
          </button>
          <button
            onClick={() => setDarkMode(!darkMode)}
            className={`p-2 rounded ${darkMode ? 'bg-yellow-300 text-black' : 'bg-gray-800 text-white'}`}
          >
            {darkMode ? '☀️' : '🌙'}
          </button>
          <button
            onClick={exportPDF}
            className="p-2 rounded bg-red-500 text-white"
          >
            📄 Export PDF
          </button>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-4">
        {selectedBot !== 'ARCOTEL Voicebot' && (
          <>
            <StatCard title="Anrufe gesamt" value={stats.reduce((acc, day) => acc + day.total_calls_akw, 0)} icon="📞" darkMode={darkMode} />
            <StatCard title="Rezeption" value={stats.reduce((acc, day) => acc + day.reception_calls_akw, 0)} icon="📠" darkMode={darkMode} />
            <StatCard title="Deutsch" value={stats.reduce((acc, day) => acc + day.german_calls_akw, 0)} icon="🇩🇪" darkMode={darkMode} />
            <StatCard title="Englisch" value={stats.reduce((acc, day) => acc + day.english_calls_akw, 0)} icon="🇬🇧" darkMode={darkMode} />
          </>
        )}
        {selectedBot === 'ARCOTEL Voicebot' && (
          <>
            <StatCard title="Anrufe gesamt" value={stats.reduce((acc, day) => acc + day.total_calls, 0)} icon="📞" darkMode={darkMode} />
            <StatCard title="Geschäftliche Anrufe" value={stats.reduce((acc, day) => acc + day.business_calls, 0)} icon="💼" darkMode={darkMode} />
            <StatCard title="Private Anrufe" value={stats.reduce((acc, day) => acc + day.private_calls, 0)} icon="🏠" darkMode={darkMode} />
            <StatCard title="Buchungslinks gesendet" value={stats.reduce((acc, day) => acc + day.booking_links_sent, 0)} icon="🔗" darkMode={darkMode} />
            <StatCard title="Buchungsfragen" value={stats.reduce((acc, day) => acc + day.existing_booking_calls, 0)} icon="❓" darkMode={darkMode} />
          </>
        )}
      </div>
  
      <div className="mt-8">
        <h2 className="text-xl font-bold mb-4">Anrufstatistik</h2>
        {renderChart()}
      </div>
    </div>
  );  

  const renderConversations = () => (
    <div className={`p-4 ${darkMode ? 'bg-gray-800 text-white' : 'bg-white'}`}>
      <h2 className="text-xl font-bold mb-4">Konversationen</h2>
      <div className="flex">
        <div className="w-full md:w-1/4">
          <div className="space-y-2">
            {conversations.map((conv, index) => (
              <div key={index} className={`border p-2 rounded-lg cursor-pointer ${darkMode ? 'border-gray-600' : 'border-gray-200'}`}
                   onClick={() => setSelectedConversationId(selectedConversationId === conv.id ? null : conv.id)}>
                <h3 className="font-bold">Conversation #{conv.id}</h3>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteConversation(conv.id);
                  }}
                  className="text-red-500"
                >
                  🗑️
                </button>
              </div>
            ))}
          </div>
        </div>
        <div className="w-full md:w-3/4 mt-4 md:mt-0">
          {selectedConversationId && (
            <div className={`p-4 rounded-lg ${darkMode ? 'bg-gray-700' : 'bg-white'}`}>
              <h3 className="font-bold">Anruf ID: {conversations.find(conv => conv.id === selectedConversationId).call_id}</h3>
              <div className="space-y-2">
                {conversations.find(conv => conv.id === selectedConversationId).content.split('\n').map((line, index) => (
                  <div key={index} className={`p-2 rounded-lg max-w-md ${line.startsWith('User:') ? 'self-start bg-blue-500 text-white' : 'self-end bg-green-500 text-white'}`}>
                    {line}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  const saveBotConfig = async () => {
    try {
      const response = await fetch(`${AKW_API_BASE_URL}/api/create_bot`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(botConfig),
      });
  
      if (!response.ok) {
        throw new Error('Fehler beim Speichern der Bot-Konfiguration');
      }
  
      // Füge den neuen Bot zur Liste hinzu und wähle ihn aus
      setBots([...bots, botConfig.hotelName]);
      setSelectedBot(botConfig.hotelName);
  
      // Zurücksetzen des Konfigurationsformulars
      setBotConfig({
        hotelName: '',
        hotelShortcode: '',
        welcomeMessage: '',
        language: 'de',
        twilioNumber: '',
        port: '',
      });
      setShowCreateBotModal(false);
    } catch (error) {
      console.error('Fehler beim Speichern der Bot-Konfiguration:', error);
    }
  };
  

  const renderSettingsContent = () => {
    if (selectedBot !== 'ARCOTEL Voicebot') {
      return (
        <div>
          <button
            onClick={() => setActiveTab('faqs')}
            className={`w-full flex items-center p-2 ${darkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-300'}`}
          >
            <span className="mr-2">📄</span>
            {sidebarOpen && <span>FAQs</span>}
          </button>
          <button
            onClick={() => setActiveTab('database')}
            className={`w-full flex items-center p-2 ${darkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-300'}`}
          >
            <span className="mr-2">🔗</span>
            {sidebarOpen && <span>Datenbank</span>}
          </button>
        </div>
      );
    }
    return null; // Keine zusätzlichen Menüoptionen für den ARCOTEL Voicebot
  };
  

  if (!isAuthenticated) {
    return <Login />;
  }

  return (
    <div className={`flex h-screen ${darkMode ? 'bg-gray-900' : 'bg-gray-100'}`}>
      <div className={`${darkMode ? 'bg-gray-800' : 'bg-gray-200'} text-${darkMode ? 'white' : 'black'} ${sidebarOpen ? 'w-64' : 'w-16'} transition-all duration-300 ease-in-out`}>
        <div className="p-4 flex justify-between items-center">
          <img
            src={sidebarOpen ? (darkMode ? arcotel : arcotelDunkel) : logoArcotel}
            alt="ARCOTEL Logo"
            className={`${sidebarOpen ? 'w-48' : 'w-48'} transition-all duration-300`}
          />
          <button onClick={() => setSidebarOpen(!sidebarOpen)} className={`text-${darkMode ? 'white' : 'black'}`}>
            {sidebarOpen ? '◀' : '▶'}
          </button>
        </div>
        <nav className="mt-8">
  <SidebarItem icon="📊" text="Dashboard" active={activeTab === 'dashboard'} onClick={() => setActiveTab('dashboard')} sidebarOpen={sidebarOpen} darkMode={darkMode} />
  <SidebarItem icon="💬" text="Konversationen" active={activeTab === 'conversations'} onClick={() => setActiveTab('conversations')} sidebarOpen={sidebarOpen} darkMode={darkMode} />
  {renderSettingsContent()}
  <SidebarItem icon="➕" text="Bot erstellen" onClick={() => setShowCreateBotModal(true)} sidebarOpen={sidebarOpen} darkMode={darkMode} />
  <SidebarItem icon="🚪" text="Logout" onClick={logout} sidebarOpen={sidebarOpen} darkMode={darkMode} />
</nav>

      </div>

      <div className="flex-1 overflow-auto">
        <div className={`${darkMode ? 'bg-gray-700' : 'bg-white'} shadow-md p-4`}>
          <h1 className={`text-2xl font-bold ${darkMode ? 'text-white' : 'text-black'}`}>
            {selectedBot}
          </h1>
        </div>
        {activeTab === 'dashboard' ? renderDashboard() : activeTab === 'conversations' ? renderConversations() : activeTab === 'faqs' ? <FAQs darkMode={darkMode} /> : <DatabaseSettings darkMode={darkMode} />}
      </div>

      {showCreateBotModal && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50">
          <div className={`bg-white rounded p-4 ${darkMode ? 'bg-gray-800 text-white' : 'bg-white'}`}>
            <h2 className="text-xl font-bold mb-4">Neuen Bot erstellen</h2>
            <form onSubmit={(e) => { e.preventDefault(); saveBotConfig(); }}>
              <input
                type="text"
                placeholder="Hotelname"
                value={botConfig.hotelName}
                onChange={(e) => setBotConfig({ ...botConfig, hotelName: e.target.value })}
                className={`p-2 border rounded mb-4 w-full ${darkMode ? 'bg-gray-700 text-white' : 'bg-white'}`}
                required
              />
              <input
                type="text"
                placeholder="Hotelkürzel"
                value={botConfig.hotelShortcode}
                onChange={(e) => setBotConfig({ ...botConfig, hotelShortcode: e.target.value })}
                className={`p-2 border rounded mb-4 w-full ${darkMode ? 'bg-gray-700 text-white' : 'bg-white'}`}
                required
              />
              <input
                type="text"
                placeholder="Willkommensnachricht"
                value={botConfig.welcomeMessage}
                onChange={(e) => setBotConfig({ ...botConfig, welcomeMessage: e.target.value })}
                className={`p-2 border rounded mb-4 w-full ${darkMode ? 'bg-gray-700 text-white' : 'bg-white'}`}
                required
              />
              <input
                type="text"
                placeholder="Twilio Telefonnummer"
                value={botConfig.twilioNumber}
                onChange={(e) => setBotConfig({ ...botConfig, twilioNumber: e.target.value })}
                className={`p-2 border rounded mb-4 w-full ${darkMode ? 'bg-gray-700 text-white' : 'bg-white'}`}
                required
              />
              <input
                type="text"
                placeholder="Port"
                value={botConfig.port}
                onChange={(e) => setBotConfig({ ...botConfig, port: e.target.value })}
                className={`p-2 border rounded mb-4 w-full ${darkMode ? 'bg-gray-700 text-white' : 'bg-white'}`}
                required
              />
              <div className="flex justify-end space-x-2">
                <button
                  type="button"
                  onClick={() => setShowCreateBotModal(false)}
                  className="p-2 rounded bg-gray-500 text-white"
                >
                  Abbrechen
                </button>
                <button
                  type="submit"
                  className="p-2 rounded bg-blue-500 text-white"
                >
                  Bot erstellen
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

const StatCard = ({ title, value, icon, darkMode }) => (
  <div className={`p-4 rounded-lg shadow ${darkMode ? 'bg-gray-700' : 'bg-white'}`}>
    <div className="flex justify-between items-center">
      <div>
        <p className={`text-sm ${darkMode ? 'text-gray-300' : 'text-gray-500'}`}>{title}</p>
        <p className="text-2xl font-bold">{value}</p>
      </div>
      <div className="text-3xl text-blue-400">{icon}</div>
    </div>
  </div>
);

const SidebarItem = ({ icon, text, active, onClick, sidebarOpen, darkMode }) => (
  <button
    className={`w-full flex items-center p-2 ${active ? (darkMode ? 'bg-gray-700' : 'bg-gray-300') : (darkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-300')}`}
    onClick={onClick}
  >
    <span className="mr-2">{icon}</span>
    {sidebarOpen && <span>{text}</span>}
  </button>
);

const DashboardWithTheme = () => (
  <ThemeProvider>
    <AuthProvider>
      <AdvancedCallTrackingDashboard />
    </AuthProvider>
  </ThemeProvider>
);

export default DashboardWithTheme;
